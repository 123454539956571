import React, { useEffect, useState } from "react";
import { getAllDepartmentList, DeleteDepartmentData } from "../../targetactioncreator";
import moment from "moment";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../../../images/nodata.png';

const EmpList = ({ editHandler, showButton }) => {
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [departmentData, setDepartmentData] = useState({});
    const [expandedDepartment, setExpandedDepartment] = useState(null);

    const handleDelete = (data) => {
        setLoader(true);
        DeleteDepartmentData(data?.id, (callback) => {
            setLoader(false);
            if (callback?.status === 200) {
                getDepartments();
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        });
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = () => {
        setLoader(true);
        getAllDepartmentList(callback => {
            setLoader(false);
            if (callback?.status === 200) {
                setDepartmentData(callback.data);
            }
        });
    };

    const closePopup = () => {
        setPopup(false);
    };

    const toggleAccordion = (departmentId) => {
        setExpandedDepartment(expandedDepartment === departmentId ? null : departmentId);
    };

    return (
        <div className='projection-new department-main'>
            {loader && <Loader />}
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            <div className="container-fluid Projection" style={{ padding: '20px 50px' }}>
                {Object.keys(departmentData).length > 0 ? (
                    <div className="accords openaccord">
                        {Object.entries(departmentData).map(([departmentId, departmentInfo]) => {
                            const departmentDetails = departmentInfo.departmentDetails || {};
                            return (
                                <div key={departmentId} className="accordion-item" style={{ background: '#f2f2f2', marginBottom: '10px', borderRadius: '5px', cursor: 'pointer' }}>
                                    <div
                                        className="accordion-header"
                                        style={{ cursor: 'pointer', padding: '10px', background: 'rgb(219, 233, 255)', color: '#000', borderRadius: '5px' }}
                                        onClick={() => toggleAccordion(departmentId)}
                                    >
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12">
                                                <label style={{ color: 'grey' }}>Department Name</label> <br /><h4>{departmentDetails.departmentName || 'N/A'}</h4>
                                            </div>
                                            <div className="col-sm-2 col-xs-12">
                                                <label style={{ color: 'grey' }}>Salary Target</label> <br /><h4>{departmentDetails.salaryTarget || 'N/A'}</h4>
                                            </div>
                                            <div className="col-sm-2 col-xs-12">
                                                <label style={{ color: 'grey' }}>Total Employees</label> <br /><h4>{departmentDetails.totalEmployee || 'N/A'}</h4>
                                            </div>
                                            <div className="col-sm-2 col-xs-12">
                                                <label style={{ color: 'grey' }}>Disbursed Amount</label> <br /><h4>{departmentDetails.disbursedAmount || 'N/A'}</h4>
                                            </div>
                                            <div className="col-sm-2 col-xs-12">
                                                <label style={{ color: 'grey' }}>Salary Disbursed (%)</label> <br /><h4>{departmentDetails.salaryDisbursedInPercentage || 'N/A'}</h4>
                                            </div>
                                            <div className="col-sm-1 col-xs-12 text-right" style={{ fontSize: '40px' }}>
                                                <b>{expandedDepartment === departmentId ? '-' : '+'}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ overflow: 'auto' }} className={`accordion-body ${expandedDepartment === departmentId ? 'expanded' : ''}`}>
                                        {showButton ? <div className="text-right">
                                            <i
                                                className="fa fa-pencil-square-o fa-lg"
                                                aria-hidden="true"
                                                style={{ cursor: 'pointer', marginRight: '15px' }}
                                                onClick={() => editHandler(departmentDetails)}
                                            ></i>
                                            <i
                                                className="fa fa-trash-o fa-lg"
                                                aria-hidden="true"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleDelete(departmentDetails)}
                                            ></i>
                                        </div>
                                            : ""}
                                        <h5>Employee Details</h5>

                                        <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px', borderRadius: '16px', }}>
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Employee Name</th>
                                                        <th>In-Hand Salary</th>
                                                        <th>Attendance</th>
                                                        <th>Month Name</th>
                                                        <th>Attendance Added By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {departmentInfo.employeeDetails && departmentInfo.employeeDetails.length > 0 ? (
                                                        departmentInfo.employeeDetails.map((employee, index) => (
                                                            <tr key={index}>
                                                                <td>{employee.employeeName || 'N/A'}</td>
                                                                <td>{employee.inHandSalary || 'N/A'}</td>
                                                                <td>
                                                                    {employee.lastMonthPresent ? `${employee.lastMonthPresent} days` : 'N/A'} /
                                                                    {employee.lastMonthAbsent ? `${employee.lastMonthAbsent} days` : 'N/A'}
                                                                </td>
                                                                <td>{employee.monthName || 'N/A'}</td>
                                                                <td>{employee.attendanceAddedBy || 'N/A'}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5">No employees available in this department.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="text-center" style={{ marginTop: '50px' }}>
                        <img src={NodataImage} alt="No Data" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmpList;
