import React, { useState, useEffect } from 'react';
import reseticonimg from './images/reseticon.png';
import { getUnidetifiedPaymentsApi } from './actionCreator';
import Loader from '../../../presentationals/Loader/DotLoader.component';
import Nodataimage from '../../../../images/nodata.png'
export default function MandateReport() {
    const [loaderState, setLoader] = useState(false);

    const [fromdate, setfromdate] = useState('');
    const [todate, settodate] = useState('');
    const [tableData, setTableData] = useState([]);

    const getData = (fromDate, toDate) => {
        setLoader(true);
        getUnidetifiedPaymentsApi(fromDate, toDate, (response) => {
            setTableData(response || []); 
            setLoader(false);
        });
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;

        if (name === "fromDate") {
            setfromdate(value);
            if (new Date(value) > new Date(todate)) {
                settodate(value); 
            }
        } else {
            settodate(value);
        }
    };

    const handlefilter = (e) => {
        setfiltertype(e.target.value);
        e.target.value === 'duration'
            ? setsubfiltertype('last7days')
            : e.target.value === 'mandatestatus'
            ? setsubfiltertype('all')
            : setsubfiltertype('');
    };

    const handlefiltersecondary = (e) => {
        setsubfiltertype(e.target.value);
    };

    useEffect(() => {
        if (fromdate && todate) {
            getData(fromdate, todate);
        }
    }, [fromdate, todate]);
    const resetHandler=()=>{
        setLoader(true);
        getUnidetifiedPaymentsApi('', '', (response) => {
            setTableData(response || []); 
            setLoader(false);
        });
    }

    return (
        <>
            {loaderState && <Loader />}
            <div className="MandateReport AccountReconciliaiton-CSS container-fluid full-height">
                <div className="row" style={{ marginTop: '20px', paddingLeft: '15px' }}>
                    <h3 className="mb-0"><b>Unidentified Payments</b></h3>
                </div>

                <div className="row" style={{ marginTop: '20px' }}>
                   
                            <div className="col-sm-2 col-xs-12" >
                                <div className="dates">
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        name="fromDate"
                                        value={fromdate}
                                        onChange={handleDateChange}
                                        className="form-control"
                                    />
                                </div>
                                </div>
                                <div className="col-sm-2 col-xs-12" >

                                <div className="dates">
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        name="toDate"
                                        value={todate}
                                        min={fromdate}
                                        onChange={handleDateChange}
                                        className="form-control"
                                    />
                                </div>
               
                    </div>
                    <div className="col-sm-2">
                        <button className="reset-btn" onClick={() => {
                          resetHandler()
                        }}>
                            <img src={reseticonimg} alt="Reset" width="25%" /> Reset Filter
                        </button>
                    </div>
                </div>
{tableData.length>0?
                <div className="row" style={{ padding: '10px', paddingRight: '15px' }}>
                    <div className="table-responsive phocket-table-new">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Payment Id</th>
                                    <th>RazorPay Order Id</th>
                                    <th>Amount</th>
                                    <th>Email Id</th>
                                    <th>Contact Number</th>
                                    <th>Created At</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.length > 0 ? tableData.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.paymentId}</td>
                                        <td>{data.raazorPayOrderId}</td>
                                        <td>{`₹ ${data.amount}`}</td>
                                        <td>{data.emailId}</td>
                                        <td>{data.number}</td>
                                        <td>{new Date(data.getTransactionDate).toLocaleString()}</td>
                                        <td style={{ color: data.status === 'Success' ? 'green' : 'red' }}>
                                            {data.status}
                                        </td>
                                    </tr>
                                )) : ""}
                            </tbody>
                        </table>
                    </div>
                </div>
                :<div className='text-center'><img src={Nodataimage} style={{width:'30%',marginTop:'4%'}}/></div>}
            </div>
        </>
    );
}
