import React, { useState } from "react";
import MainEmp from './listingmain'
import MainDepartment from './mainconfiguration'
import MainTarget from './Targetssalary/mainTargets'
import BackLogo from '../../../../../images/rewampimages/Chevron.png'
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PunchClockIcon from '@mui/icons-material/PunchClock';


const TabsExpense = ({ pageViewHandler, admin }) => {
    const [viewState, setViewState] = useState('list')


    const BackBtnStyle2 = {
        color: '#fff',
        fontWeight: '400',
        display: 'flex',
        background: '#223d64',
        cursor: 'pointer',
        padding: '12px 20px',
        'boxShadow': 'inset -4px -4px 4px 0px #1d252d'
    }


    return (
        <>
            {/* <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
                <div className="" onClick={(e) => pageViewHandler(e, 'landingPage')} style={BackBtnStyle2}>
                    <ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back

                </div>
                <div className="" style={{ width: '33.33%' }}>
                </div>
                <div className="" onClick={() => setViewState('list')} style={{ borderBottom: viewState === 'list' ? '2px solid #267DFF' : '', background: viewState === 'list' ? '#267DFF' : '#DBE9FF', padding: viewState === 'list' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'list' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Listing
                </div>
                <div className="" onClick={() => setViewState('config')} style={{ borderBottom: viewState === 'config' ? '2px solid #267DFF' : '', background: viewState === 'config' ? '#267DFF' : '#DBE9FF', padding: viewState === 'config' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'config' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Configurations

                </div>


            </div> */}
            <div className="container-fluid details-new fixed-top"  >
                <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }}>
                    <div className="col-sm-6 col-xs-12 " style={{ marginTop: '15px' }}>
                        <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button>
                    </div>

                    <div className=" col-lg-6 col-xs-12" style={{ fontSize: '10px', display: 'flex', justifyContent: 'end' }}>


                        <div className="btn-group-new">

                            <button className={(viewState === 'list') ? "active" : ""} onClick={(e) => setViewState('list')}><WalletIcon style={{ fontSize: '27px' }} /><br /> Listing</button>
                            {/* <button className={(viewState === 'attendence') ? "active" : ""} onClick={(e) => setViewState(e, 'attendence')}><PunchClockIcon style={{ fontSize: '27px' }} /><br />Attendance</button> */}
                            <button className={(viewState === 'config') ? "active" : ""} onClick={(e) => setViewState('config')}><SettingsIcon style={{ fontSize: '27px' }} /><br /> Configuration</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="" style={{ marginTop: '6%' }}>

                {viewState === 'list' ?
                    <MainEmp admin={admin} />
                    : ""}
                {viewState === 'config' ?
                    <MainDepartment admin={admin} />
                    : ""}
               

            </div>
        </>
    )
}
export default TabsExpense