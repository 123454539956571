import React, { useState } from "react";
import MainExpenses from "./main";
import MainCategory from "./category/main";
import MainSubCategory from "./subcategory/main";
import MainDescription from "./description/main";
import MainTarget from "./Targets/main";
import MainTargetList from './NewTargetList/main';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import BackLogo from '../../../../../images/rewampimages/Chevron.png'
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
const TabsExpense = ({ pageViewHandler,admin }) => {
    const [viewState, setViewState] = useState('expense')
    const [configState, setconfigState] = useState('')

    const BackBtnStyle = {
        color: '#223d64', 
        fontWeight: '400', 
        display: 'flex', 
        border: '1px solid #267dff', 
        borderRadius: '6px', 
        padding: '8px 10px',
        marginLeft: viewState === 'expense' || viewState === 'target' ? '0px' : '80px',
    }
    const BackBtnStyle2 = {
        color: '#fff', 
        fontWeight: '400', 
        display: 'flex', 
        background:'#223d64',
        cursor:'pointer',
        padding: '12px 20px',
        'boxShadow': 'inset -4px -4px 4px 0px #1d252d'
    }


    const handeNavbarState =(e,type)=>{
        if(type == 'expense' || type == 'targetNewList' ){
            setconfigState('')
            setViewState(type)
        }else if(type == 'config'){
            setconfigState(type)
            setViewState('category')
        }
    }

    return (
        <> <div className="container-fluid details-new fixed-top"  >
        <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }}>
            <div className="col-sm-6 col-xs-12 " style={{ marginTop: '15px' }}>
                <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button>
            </div>

            <div className=" col-lg-6 col-xs-12" style={{ fontSize: '10px', display: 'flex', justifyContent: 'end' }}>


                <div className="btn-group-new">

                    <button className={(viewState === 'expense') ? "active" : ""} onClick={(e) => handeNavbarState(e,'expense')}><WalletIcon style={{ fontSize: '27px' }} /><br />Expense List</button>
                    <button className={(viewState === 'targetNewList') ? "active" : ""} onClick={(e) => handeNavbarState(e,'targetNewList')}><TrackChangesIcon style={{ fontSize: '27px' }} /><br />Target List</button>
                    <button className={(configState === 'config') ? "active" : ""} onClick={(e) => handeNavbarState(e,'config')}><SettingsIcon style={{ fontSize: '27px' }} /><br />Expense Configuration</button>
                </div>

            </div>
        </div>
    </div>
    {
        configState === 'config'?
            <div className="row fixed-top" style={{ marginTop: '75px', display: 'flex', width: '101%' }}>
                {/* <div className="" onClick={(e) => pageViewHandler(e, 'landingPage')} style={BackBtnStyle2}>
                <ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back

                </div> */}
                {/* <div className="" onClick={() => setViewState('expense')} style={{ borderBottom: viewState === 'expense' ? '2px solid #267DFF' : '', background: viewState === 'expense' ? '#267DFF' : '#DBE9FF', padding: viewState === 'expense' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'expense' ? '#fff' : '#000', cursor: 'pointer', width: '20%' }}>
                    Expense List

                </div> */}
                <div className="" onClick={() => setViewState('category')} style={{ borderBottom: viewState === 'category' ? '2px solid #267DFF' : '', background: viewState === 'category' ? '#267DFF' : '#DBE9FF', padding: viewState === 'category' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'category' ? '#fff' : '#000', cursor: 'pointer', width: '25%' }}>
                    Expense Category

                </div>
                <div className="" onClick={() => setViewState('subCategory')} style={{ borderBottom: viewState === 'subCategory' ? '2px solid #267DFF' : '', background: viewState === 'subCategory' ? '#267DFF' : '#DBE9FF', padding: viewState === 'subCategory' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'subCategory' ? '#fff' : '#000', cursor: 'pointer', width: '25%' }}>
                    Expense  Sub-Category

                </div>
                <div className="" onClick={() => setViewState('description')} style={{ borderBottom: viewState === 'description' ? '2px solid #267DFF' : '', background: viewState === 'description' ? '#267DFF' : '#DBE9FF', padding: viewState === 'description' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'description' ? '#fff' : '#000', cursor: 'pointer', width: '25%' }}>
                    Expense Description

                </div>
                <div className="" onClick={() => setViewState('target')} style={{ borderBottom: viewState === 'target' ? '2px solid #267DFF' : '', background: viewState === 'target' ? '#267DFF' : '#DBE9FF', padding: viewState === 'target' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'target' ? '#fff' : '#000', cursor: 'pointer', width: '25%' }}>
                    Expense Target

                </div>

            </div>
            :''}
            {/* <div className={viewState === 'expense' || viewState === 'target' ? "container-fluid" : "container"}>
                <div className="row">
                    <div className="col-sm-4 " style={{ marginTop: window.innerWidth > 1800 ? '0%' : '5%'}}>
                        <button style={BackBtnStyle} onClick={(e) => pageViewHandler(e, 'landingPage')} ><ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back</button>
                    </div>
                </div>
            </div> */}
             <div className="" style={{ marginTop: viewState === 'expense' ?  window.innerWidth > 1800 ? '0%' : '6%' : window.innerWidth > 1800 ? '8%' : '9%'}}>

            {viewState === 'expense' ?
                <MainExpenses admin={admin}/>
                : ""}
            {viewState === 'category' ?
                <MainCategory />
                : ""}
            {viewState === 'subCategory' ?
                <MainSubCategory />
                : ""}
            {viewState === 'description' ?
                <MainDescription />
                : ""}
            {viewState === 'target' ?
                <MainTarget />
                : ""}
            {viewState === 'targetNewList' ?
                <MainTargetList />
                : ""}
                </div>
        </>
    )
}
export default TabsExpense